<template>
  <main class="main-page">
    <div class="container space-y-11 pb-11 pt-6 lg:pt-8 xl:space-y-16 xl:pb-16">
      <div class="space-y-6 lg:space-y-8">
        <HomePageBanner
          :items="bannersNew?.sort((a, b) => a.row - b.row)"
          loop
          mobile-text-btn="Узнать подробнее"
          background="bg-blue-50"
          class-title="gradient-text w-full md:w-[50%]"
          class-text="text-blue-gray-500"
          class-button="!bg-sapphire-900 text-white button-hover-gradient"
          :logotype-banner="BannerLogoBlue"
          :logotype-banner-webp="BannerLogoBlueWebp"
          :logotype-banner-size="{
            width: 266,
            height: 52,
          }"
        />
        <div class="pb-2.5 pt-6 md:pb-0 lg:pb-3 xl:pb-11">
          <h2
            class="text-heading-2 text-sapphire-900 mb-6 text-center font-semibold leading-[38px] md:mb-8"
          >
            Преимущества Monge Natural Superpremium
          </h2>
          <Swiper
            :slides-per-view="1"
            :space-between="28"
            :pagination="{
              clickable: true,
              bulletClass:
                'block rounded-full w-2 h-2 bg-gray-200 cursor-pointer transition-all duration-200',
              bulletActiveClass: '!bg-primary !w-8',
              /* horizontalClass:
                '!static mt-3 flex justify-center space-x-2 lg:hidden'
                'TODO: check: dont work after pnpm, nuxt v3.7.3, cant add class w/whitespace',
              */
            }"
            :modules="[Pagination]"
            :breakpoints="{
              1024: {
                slidesPerView: 9,
                spaceBetween: 0,
              },
            }"
            class="flex flex-col items-center [&>div.swiper-pagination-horizontal]:!static [&>div.swiper-pagination-horizontal]:mt-3 [&>div.swiper-pagination-horizontal]:flex [&>div.swiper-pagination-horizontal]:space-x-2 [&>div.swiper-pagination-horizontal]:lg:hidden [&>div.swiper-wrapper]:lg:grid [&>div.swiper-wrapper]:lg:grid-cols-2 [&>div.swiper-wrapper]:lg:gap-x-7 [&>div.swiper-wrapper]:lg:gap-y-3"
          >
            <SwiperSlide
              v-for="item in naturalList"
              :key="item.text"
              class="lg:!w-full"
            >
              <div
                class="border-blue-gray-200 grid min-h-[158px] grid-cols-[60px_auto] gap-2 rounded-2xl border-2 bg-white p-2 sm:min-h-[250px] sm:grid-cols-[159px_auto] lg:min-h-[138px]"
              >
                <nuxt-picture
                  :src="item.icon"
                  :width="item.iconWidth || 92"
                  height="92"
                  sizes="xs:184px"
                  class="mx-auto mb-1 block self-center justify-self-center"
                  :img-attrs="{
                    alt: item.title,
                  }"
                />
                <div class="self-center">
                  <h3
                    class="text-blue-gray-700 mb-[10px] text-xl font-semibold leading-5"
                  >
                    {{ item.title }}
                  </h3>
                  <p
                    class="text-blue-gray-700 max-w-[320px] text-sm font-medium leading-tight"
                  >
                    {{ item.description }}
                  </p>
                </div>
              </div>
            </SwiperSlide>
          </Swiper>
        </div>
      </div>

      <section class="lg:!mt-0">
        <div class="hidden lg:block">
          <h2
            class="text-heading-2 text-sapphire-900 mb-6 font-semibold leading-[38px] md:mb-8"
          >
            Линии продуктов
          </h2>
          <div class="grid w-full grid-cols-2 gap-7">
            <div v-for="(item, id) in brandBanners" :key="id" class="w-full">
              <HomeLineBanner :item="item" />
            </div>
          </div>
        </div>
        <div class="block lg:hidden">
          <ItemSlider
            title="Линии продуктов"
            :items="brandBanners"
            :slides-per-view="3"
            :slides-per-view-mobile="1.4"
            unique-class="brand-banners"
            slide-class="w-[264px] h-auto md:w-[304px] lg:w-1/2"
          >
            <template #default="{ item }">
              <HomeLineBanner :item="item" />
            </template>
          </ItemSlider>
        </div>
      </section>

      <!-- <Hits v-if="!!false" /> -->

      <div
        class="bg-primary-50 relative overflow-hidden rounded-3xl px-6 pt-6 lg:p-11"
      >
        <div class="relative z-[1]">
          <h3 class="text-heading-4 lg:text-heading-2 font-semibold">
            Выбирайте правильный рацион <br />
            для вашего питомца!
          </h3>
          <p class="text-2 text-secondary mt-2 lg:mt-3.5 lg:max-w-[552px]">
            Мастер подбора поможет вам найти корм, который оптимально <br />
            подойдет для породы вашего питомца, с учетом его <br />
            индивидуальных потребностей и особенностей.
          </p>
          <Btn
            xl
            primary
            class="mt-4 hidden w-full lg:mt-11 lg:flex lg:w-[312px]"
            @click="chooseFoodMethod"
          >
            Подобрать корм
          </Btn>
        </div>
        <div
          class="-mx-6 -mt-12 block lg:absolute lg:inset-0 lg:mx-0 lg:mt-0 lg:object-right-bottom"
        >
          <HeartSvg
            class="hidden h-full max-h-full text-blue-100 lg:ml-auto lg:block lg:max-h-full"
          />
          <HeartMobileSvg
            class="h-auto w-full text-blue-100 lg:ml-auto lg:hidden lg:max-h-full lg:w-auto"
          />
          <LottieAnimation
            class="absolute -bottom-[130px] -right-[50px] h-[400px] w-[400px] md:-bottom-[112px] md:-right-[29px] md:h-[500px] md:w-[500px] lg:-top-[40px] lg:bottom-auto"
            :animation-data="AstronautJSON"
            :loop="true"
            :auto-play="true"
          />
        </div>
      </div>

      <section>
        <div class="rounded-[12px] bg-white p-8 shadow">
          <h2 class="text-heading-4 text-blue-gray-700 mb-2 leading-8">
            Как правильно перевести питомца на новый корм Monge?
          </h2>
          <p
            class="text-3 text-blue-gray-500 mb-6 max-w-[594px] leading-[19px]"
          >
            Чтобы избежать проблем с пищеварением питомца, переводите его на
            новый корм постепенно, в течение 7 дней
          </p>
          <div class="-mr-8 flex flex-wrap">
            <HomeChart
              title="Четверть порции корма Monge"
              text="1-2 день"
              chart="1"
            />
            <HomeChart
              title="Половина порции корма Monge"
              text="3-4 день"
              chart="2"
            />
            <HomeChart
              title="Три четверти порции корма Monge"
              text="5-6 день"
              chart="3"
            />
            <HomeChart
              title="Полная порция корма Monge"
              text="7 день"
              chart="4"
            />
          </div>
          <p class="text-3 text-blue-gray-500 max-w-[830px] leading-[19px]">
            Это общие показания. Данная схема также действует на новый корм в
            рамках одного бренда, если ваш питомец вырос, поменял уровень
            активности или у него появились другие показания для смены корма.
          </p>
        </div>
      </section>

      <!-- <Discounts v-if="!!false" /> -->

      <section class="grid gap-x-7 lg:grid-cols-2">
        <h2
          class="gradient-text mb-[10px] block text-[24px] font-semibold lg:hidden"
        >
          Monge - преданность традициям с 1963 года.
        </h2>
        <div class="mb-3.5 lg:order-last lg:mb-0">
          <nuxt-picture
            src="/images/home-manufacturer.png"
            width="1328"
            height="920"
            sizes="xs:100vw sm:100vw md:100vw lg:50vw xl:50vw"
            :img-attrs="{
              alt: '',
              class: 'w-full rounded-2xl object-cover object-left lg:h-[409px]',
            }"
          />
        </div>
        <div>
          <h2
            class="gradient-text text-heading-4 lg:text-heading-2 hidden font-semibold lg:block"
          >
            Monge - преданность традициям с 1963 года.
          </h2>
          <p class="text-4 text-secondary lg:text-1 mt-1.5 lg:mt-3.5">
            Уже более 60 лет назад Балдассаре Монже основал в солнечной
            провинции Пьемонт производство натуральных сбалансированных рационов
            питания кошек и собак. Он взял на себя бескомпромиссную
            ответственность за качество выпускаемой продукции, дав бренду свою
            фамилию.
          </p>

          <p class="text-4 text-secondary lg:text-1 mt-1.5 lg:mt-3.5">
            По сей день вся семья Монже вкладывает душу, навыки и свой труд для
            того, чтобы каждый питомец получал сбалансированное качественное и
            вкусное питание.
          </p>

          <Btn
            to="/about/"
            xl
            primary
            class="mt-3.5 w-full lg:mt-[32px] lg:w-[312px]"
          >
            Узнать подробнее
          </Btn>
        </div>
      </section>

      <NewsAndStocks title-class="text-primary" />

      <section v-if="false">
        <ItemSlider
          title="Истории"
          :items="stories"
          :slides-per-view="6"
          unique-class="stories"
          slide-class="!max-w-[138px] lg:!max-w-[173px]"
        >
          <template #default="{ item }">
            <div
              :class="[
                'relative flex h-[204px] items-center justify-center overflow-hidden rounded-2xl border-2 border-blue-900 bg-[#E3F3FF] bg-cover bg-no-repeat lg:h-[255px]',
              ]"
              :style="{ backgroundImage: `url(${item.image})` }"
            >
              <div class="absolute inset-0 bg-blue-900/60" />
              <nuxt-picture
                src="/_assets/images/Smiling-Face-with-Heart-Eyes.png"
                sizes="xs:100vw"
                :img-attrs="{
                  alt: '',
                  'aria-hidden': true,
                  class: 'absolute -left-6 -top-4 rotate-[30deg]',
                }"
              />
              <nuxt-picture
                src="/_assets/images/Smiling-Face-with-Hearts.png"
                sizes="xs:100vw"
                :img-attrs="{
                  alt: '',
                  'aria-hidden': true,
                  class: 'absolute -right-12 top-7 rotate-[-30deg]',
                }"
              />
              <nuxt-picture
                src="/_assets/images/Smiling-Face-with-Hearts.png"
                sizes="xs:100vw"
                :img-attrs="{
                  alt: '',
                  'aria-hidden': true,
                  class: 'absolute -bottom-7 right-2 rotate-45',
                }"
              />
              <div
                class="relative text-center text-sm font-semibold text-white lg:text-lg"
              >
                {{ item.title }}
              </div>
            </div>
          </template>
        </ItemSlider>
      </section>
    </div>
  </main>
</template>

<script setup lang="ts">
import { Pagination } from 'swiper/modules'
import { Swiper, SwiperSlide } from 'swiper/vue'
import BannerLogoBlue from '@valta/assets/images/banner-logo-blue-2.png'
import BannerLogoBlueWebp from '@valta/assets/images/banner-logo-blue-2.webp'
import AstronautJSON from '@valta/assets/animation/DogJSON.json'
import HeartSvg from '@valta/assets/images/heart.svg'
import HeartMobileSvg from '@valta/assets/images/heart-mobile.svg'
import BWildSvg from '@valta/assets/images/bwild.svg?component'
import GiftSvg from '@valta/assets/images/gift.svg?component'
import DailyLineSvg from '@valta/assets/images/daily-line.svg?component'
import SpecialityLineSvg from '@valta/assets/images/speciality-line.svg?component'

const { loggedIn, login } = useAuth()

const fetchOpenApi = useOpenApi()
const { data: bannersNew } = await useLazyAsyncData('bannersNew', () =>
  fetchOpenApi.BannersApi.bannersList(),
)

const stories = []

const naturalList = [
  {
    icon: '/images/natural-icon1.png',
    title: 'Сделано в Италии',
    description:
      'Корма для кошек и собак Monge производятся в Италии более 60 лет по строгим европейским стандартам качества',
    text: 'Сделано в Италии. Высокое качество продукции',
  },
  {
    icon: '/images/natural-icon3.png',
    title: 'Омега-3 и Омега-6',
    description:
      'Омега-3 и Омега-6 оказывают положительное влияние на здоровье кожи и шерсти питомца, а также, поддерживают его иммунитет',
    text: 'Омега-3 и Омега-6 для здоровья кожи и красоты шерсти',
  },
  {
    icon: '/images/natural-icon7.png',
    title: 'Рекомендовано ветеринарными врачами',
    description:
      'Все корма отличаются сбалансированными, вкусными и разнообразными составами',
    text: 'Рекомендовано ветеринарными врачами',
  },
  {
    icon: '/images/natural-icon4.png',
    title: 'Хондроитин и глюкозамин',
    description:
      'Комплекс веществ, улучшающих состояние суставов и соединительной ткани, способствует восстановлению хрящевой ткани',
    text: 'Хондроитин и Глюкозамин, для поддержания здоровья суставов',
  },
  {
    icon: '/images/natural-icon8.png',
    iconWidth: 124,
    title: 'Рекомендовано заводчиками',
    description:
      '«Клуб Заводчиков Валта» — сообщество профессиональных заводчиков и настоящих фанатов своего дела',
    text: 'Рекомендовано заводчиками',
  },
  {
    icon: '/images/natural-icon5.png',
    title: 'X.O.S. – Ксилоолигосахариды.',
    description:
      'Ксилоолигосахариды (X.O.S.) - пребиотики последнего поколения, играющие главную роль в поддержании здоровья кишечника',
    text: 'X.O.S. – Ксилоолигосахариды. Пребиотики для здоровья кишечника',
  },
  {
    icon: '/images/natural-icon2.png',
    title: 'Мясо – ингредиент №1',
    description:
      'Рационы Monge Superpremium – это легкоусвояемые рационы, которые содержат свежее мясо',
    text: 'Мясо ингредиент №1. Легкоусвояемые рационы, содержат свежее мясо',
  },
  {
    icon: '/images/natural-icon6.png',
    title: 'Сбалансированный состав витаминов и минералов',
    description:
      'Белки, жиры, углеводы, витамины, минералы и клетчатка – важные элементы, которые обеспечивают здоровье питомцев',
    text: 'Сбалансированный состав витаминов и минералов',
  },
]

const brandBanners = [
  {
    id: 4,
    title: 'Gift',
    content:
      'Линия полезных и вкусных лакомств для собак и кошек, обогащенных функциональными ингредиентами и суперфудами отобранными с учетом их полезных свойств для поддержания здоровья домашних питомцев.',
    class: 'bg-blue-50',
    bannerBg: {
      src: '/images/home-brand-gift.png',
      width: 580,
      height: 354,
    },
    bannerClass: 'bg-[#A9D9DD]',
    bannerLogo: GiftSvg,
    actionClass: 'bg-[#009CAA] hover:bg-[#58BCC5]',
    href: '/gift/',
  },
  {
    id: 1,
    title: 'BWild',
    content:
      'Инновационная линия ультрапремиум класса с холистическим подходом к питанию домашних питомцев. ',
    class: 'bg-green-50',
    bannerBg: {
      src: '/images/home-brand-bwild.png',
      width: 580,
      height: 354,
    },
    bannerClass: 'bg-[#8ED7B6]',
    bannerBg2: '/images/home-brand-bg-bwild.png',
    bannerLogo: BWildSvg,
    actionClass: 'bg-green-900 hover:bg-[#02441E]',
    href: '/bwild/',
  },
  {
    id: 2,
    title: 'Speciality Line',
    content:
      'Линия повседневных кормов суперпремиум класса для питомцев с разными физиологическими потребностями в питании. ',
    class: 'bg-blue-50',
    bannerBg: {
      src: '/images/home-brand-special.png',
      width: 528,
      height: 354,
    },
    bannerClass: 'bg-blue-100',
    bannerLogo: SpecialityLineSvg,
    href: '/catalog/speciality/',
  },
  {
    id: 3,
    title: 'Daily Line',
    content:
      'Линия повседневных кормов суперпремиум на основе легкоусвояемого мяса итальянской курицы.',
    class: 'bg-red-50',
    bannerBg: {
      src: '/images/home-brand-daily.png',
      width: 488,
      height: 354,
    },
    bannerClass: 'bg-red-100',
    bannerLogo: DailyLineSvg,
    actionClass: 'bg-red-900 hover:bg-dark-red-900',
    href: '/catalog/daily/',
  },
]

async function chooseFoodMethod() {
  const path = '/profile/pets/create/'
  if (loggedIn.value) {
    await navigateTo(path)
  } else {
    await login(path)
  }
}
</script>
